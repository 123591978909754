body {
  font-size: 13px;
  font-family: "Roboto", sans-serif !important;
  line-height: 26px !important;
  color: #6c6c6c !important;
  background-color: #f0f0f0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #1f1f1f;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 5px;
  letter-spacing: -0.4px;
}
h1 {
  font-size: 24px !important;
  line-height: 30px;
}
h2 {
  font-size: 22px !important;
  line-height: 25px;
}
h3 {
  font-size: 20px !important;
  line-height: 24px;
}
h4 {
  font-size: 18px !important;
  line-height: 23px;
}
h5 {
  font-size: 16px !important;
  line-height: 22px;
}
h6 {
  font-size: 14px !important;
  line-height: 20px;
}
a {
  text-decoration: none !important;
}
p {
  color: #6c6c6c;
  margin-bottom: 15px;
  padding-bottom: 0px;
}
#page {
  position: relative;
  overflow-x: hidden !important;
}
.page-content {
  overflow-x: hidden !important;
  transition: all 300ms ease;
  z-index: 90;
  padding-bottom: 80px;
  padding-bottom: calc(80px + (constant(safe-area-inset-bottom)) * 1.1);
  padding-bottom: calc(80px + (env(safe-area-inset-bottom)) * 1.1);
  min-height: 100vh;
}
.ad-scroll-over {
  background-size: cover;
  top: 200px;
  right: 0px;
  bottom: 200px;
  left: 0px;
  position: fixed;
  z-index: -1;
  backface-visibility: visible !important;
}
.ad-scroll-over-clear {
  pointer-events: none !important;
  backface-visibility: visible !important;
}
.content-box {
  background-color: #fff;
  border-radius: 15px;
  margin-left: 15px;
  margin-right: 15px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
}
.content {
  margin: 20px 15px 20px 15px;
}
.content p:last-child {
  margin-bottom: 0px;
}
.content-full {
  margin: 0px;
}
.content-boxed {
  padding: 20px 15px 0px 15px;
}
.header-logo {
  background-size: 28px 28px;
  width: 50px;
}
.footer .footer-logo {
  background-size: 80px 80px;
  height: 80px !important;
  margin-bottom: 20px !important;
}
.header-title span {
  font-family: "Poppins", sans-serif;
}
.footer-title span {
  font-family: "Poppins", sans-serif;
}
.header-logo {
  color: rgba(0, 0, 0, 0) !important;
}
.header-logo span {
  display: none;
  display: none;
}
.header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 55px;
  z-index: 100;
  transition: all 300ms ease;
  background-color: rgb(255, 255, 255);
  border-bottom: solid 1px rgba(0, 0, 0, 0.03);
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.08);
}
.header .header-subtitle {
  font-size: 12px !important;
  font-weight: 600 !important;
}
.header .header-title {
  position: absolute;
  height: 55px;
  line-height: 55px;
  font-size: 15px;
  color: #000;
  font-weight: 800;
  z-index: 1;
  font-family: "Poppins", sans-serif;
}
.header .header-logo {
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 50%;
}
.header .header-icon {
  position: absolute;
  height: 55px;
  width: 45px;
  text-align: center;
  line-height: 54px;
  font-size: 15px;
  color: #1f1f1f;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0) !important;
}
.header .dropdown-menu {
  z-index: 99;
}
.header-nav {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 55px;
  z-index: 99;
  transition: all 300ms ease;
}
.header-nav .header-title {
  position: absolute;
  height: 55px;
  line-height: 55px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  z-index: 1;
  font-family: "Poppins", sans-serif;
}
.header-nav .header-logo {
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 50%;
}
.header-nav .header-icon {
  position: absolute;
  height: 55px;
  width: 45px;
  text-align: center;
  line-height: 54px;
  font-size: 12px;
  color: #1f1f1f;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0) !important;
}
.footer .footer-logo {
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 50%;
}
.header-auto-show {
  opacity: 0;
  pointer-events: none;
  transition: all 300ms ease;
}
.header-active {
  opacity: 1;
  pointer-events: all !important;
  transition: all 300ms ease;
}
.header-always-show .header {
  margin-top: 0px !important;
}
.header-static {
  position: relative !important;
}
.header-transparent {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  border: none !important;
}
.header-clear {
  padding-top: 55px;
}
.header-clear-small {
  padding-top: 25px;
}
.header-clear-medium {
  padding-top: 75px;
}
.header-clear-large {
  padding-top: 90px;
}
.header-icon .badge {
  position: absolute;
  margin-left: -5px;
  color: #fff !important;
  width: 16px;
  text-align: center;
  line-height: 16px;
  padding: 0px;
  padding-left: 1px !important;
  border-radius: 18px;
  margin-top: 7px;
  font-size: 11px;
}
.header-transparent-light {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none !important;
}
.header-transparent-light a {
  color: #fff;
}
.header-transparent-dark {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none !important;
}
.header-transparent-dark a {
  color: #000;
}
.header-logo-center .header-icon-1 {
  left: 0px;
}
.header-logo-center .header-icon-2 {
  left: 45px;
}
.header-logo-center .header-icon-3 {
  right: 45px;
}
.header-logo-center .header-icon-4 {
  right: 0px;
}
.header-logo-center .header-title {
  width: 150px;
  left: 50%;
  margin-left: -75px;
  text-align: center;
}
.header-logo-center .header-logo {
  background-position: center center;
  width: 150px !important;
  left: 50%;
  margin-left: -75px;
  text-align: center;
}
.header-logo-left .header-icon-1 {
  right: 0px;
}
.header-logo-left .header-icon-2 {
  right: 45px;
}
.header-logo-left .header-icon-3 {
  right: 90px;
}
.header-logo-left .header-icon-4 {
  right: 135px;
}
.header-logo-left .header-logo {
  left: 0px !important;
  margin-left: 0px !important;
}
.header-logo-left .header-title {
  left: 15px !important;
  margin-left: 0px !important;
}
.header-logo-right .header-icon-1 {
  left: 0px;
}
.header-logo-right .header-icon-2 {
  left: 45px;
}
.header-logo-right .header-icon-3 {
  left: 90px;
}
.header-logo-right .header-icon-4 {
  left: 135px;
}
.header-logo-right .header-logo {
  left: auto !important;
  right: 20px !important;
  margin-left: 0px !important;
}
.header-logo-right .header-title {
  left: auto !important;
  right: 20px !important;
  margin-left: 0px !important;
}
.header-logo-app .header-icon-1 {
  left: 3px;
}
.header-logo-app .header-icon-2 {
  right: 2px;
}
.header-logo-app .header-icon-3 {
  right: 45px;
}
.header-logo-app .header-icon-4 {
  right: 90px;
}
.header-logo-app .header-logo {
  left: 0px !important;
  margin-left: 40px !important;
}
.header-logo-app .header-title {
  left: 0px !important;
  margin-left: 55px !important;
}
.footer-logo span {
  display: none;
}
.header-demo {
  z-index: 98 !important;
  position: relative;
}
.header-clear {
  padding-top: calc(55px + (constant(safe-area-inset-top)) * 0.75) !important;
  padding-top: calc(55px + (env(safe-area-inset-top)) * 0.75) !important;
}
.header-clear-small {
  padding-top: calc(5px + (constant(safe-area-inset-top)) * 0.75) !important;
  padding-top: calc(5px + (env(safe-area-inset-top)) * 0.75) !important;
}
.header-clear-medium {
  padding-top: calc(60px + (constant(safe-area-inset-top)) * 0.75) !important;
  padding-top: calc(60px + (env(safe-area-inset-top)) * 0.75) !important;
}
.header-clear-large {
  padding-top: calc(80px + (constant(safe-area-inset-top)) * 0.75) !important;
  padding-top: calc(80px + (env(safe-area-inset-top)) * 0.75) !important;
}
.header {
  height: calc(60px + (constant(safe-area-inset-top)) * 0.75) !important;
  height: calc(60px + (env(safe-area-inset-top)) * 0.75) !important;
}
.header-icon {
  margin-top: calc(0px + (constant(safe-area-inset-top)) * 0.75) !important;
  margin-top: calc(0px + (env(safe-area-inset-top)) * 0.75) !important;
}
.header-title {
  margin-top: calc(0px + (constant(safe-area-inset-top)) * 0.75) !important;
  margin-top: calc(0px + (env(safe-area-inset-top)) * 0.75) !important;
}
.page-content .page-title {
  margin-top: calc(20px + (constant(safe-area-inset-top)) * 0.75) !important;
  margin-top: calc(20px + (env(safe-area-inset-top)) * 0.75) !important;
}
#footer-bar {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 98;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.06);
  min-height: 60px;
  display: flex;
  text-align: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: all 300ms ease;
  height: calc(65px + (constant(safe-area-inset-bottom)) * 1.1);
  height: calc(65px + (env(safe-area-inset-bottom)) * 1.1);
}
#footer-bar a {
  color: #1f1f1f;
  padding-top: 12px;
  position: relative;
  flex: 1 1 20%;
}
#footer-bar .nav-icon {
  display: block;
  margin: auto;
  width: 20px;
  height: 20px;
}
#footer-bar a span {
  position: relative;
  z-index: 2;
  display: block;
  font-size: 10px;
  font-weight: 500;
  margin-top: -6px;
  opacity: 0.7;
  font-family: "Roboto", sans-serif !important;
}
#footer-bar a svg {
  margin-top: -8px;
}
#footer-bar a i {
  margin-top: -3px;
  font-size: 18px;
  position: relative;
  z-index: 2;
}
#footer-bar .badge {
  font-style: normal;
  z-index: 5;
  top: 0px;
  position: absolute;
  margin-left: 3px;
  color: #fff !important;
  width: 18px;
  text-align: center;
  line-height: 18px;
  padding: 0px;
  padding-left: 0px !important;
  border-radius: 18px;
  margin-top: 7px;
  font-size: 11px;
}
.footer-bar-2 .active-nav {
  color: #fff !important;
}
.footer-bar-2 .active-nav strong {
  position: absolute;
  width: 80px;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
  bottom: 0px;
}
.footer-bar-4 .active-nav {
  color: #fff !important;
}
.footer-bar-4 .active-nav strong {
  position: absolute;
  width: 47px;
  height: 47px;
  border-radius: 60px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  bottom: 0px;
}
.footer-bar-4 span {
  display: none !important;
}
.footer-bar-4 i {
  padding-top: 10px;
}
.footer-bar-5 .active-nav strong {
  position: absolute;
  width: 50px;
  height: 4px;
  border-radius: 60px;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
}
.footer-bar-3 span {
  display: none !important;
}
.footer-bar-3 .active-nav {
  padding-top: 11px !important;
}
.footer-bar-3 .active-nav span {
  display: block !important;
}
.footer-bar-3 a {
  padding-top: 18px !important;
}
.footer-menu-hidden {
  transition: all 100ms ease;
  transform: translateY(100%) !important;
}
.footer-bar-white * {
  color: #fff;
}
#footer-bar.position-relative {
  z-index: 2 !important;
}
.is-ios #footer-bar {
  height: calc(65px + (constant(safe-area-inset-bottom)) * 1.1);
  height: calc(65px + (env(safe-area-inset-bottom)) * 1.1);
}
.is-not-ios .footer-menu-clear {
  height: 70px;
  display: block;
}
.is-not-ios .footer {
  padding-bottom: 0px;
}
.is-not-ios #footer-menu a i {
  padding-top: 13px;
}
.is-not-ios #footer-menu a span {
  opacity: 0.6;
}
.footer-bar-5.position-relative .active-nav strong {
  bottom: 0px !important;
}
.menu-title h1 {
  font-size: 23px;
  font-weight: 700;
  padding: 20px 0px 3px 20px;
}
.menu-title p {
  font-size: 11px;
  margin: -15px 0px 10px 20px;
  padding: 0px 0px 0px 0px;
}
.menu-title a {
  font-size: 20px;
  color: #dc3545;
  position: absolute;
  text-align: center;
  width: 55px;
  line-height: 85px;
  right: 0px;
  top: 0px;
}
.menu-bg {
  background-color: #f0f0f0;
}
.menu-hider {
  position: fixed;
  top: -100px;
  bottom: -100px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transition: all 300ms ease;
}
.menu {
  display: flex !important;
  width: calc(100vw - 20px);
  z-index: 99999;
  flex-direction: column;
  position: fixed;
  background-color: #ffffffd6;
  backdrop-filter: blur(15px) saturate(6);
  -webkit-backdrop-filter: blur(15px) saturate(6);
  overflow: scroll;
  transition: all 300ms ease;
  -webkit-overflow-scrolling: touch;
}
.menu .content {
  margin-bottom: 0px !important;
}
.menu-box-left {
  left: 0px;
  top: 0px !important;
  top: constant(safe-area-inset-top) !important;
  top: env(safe-area-inset-top) !important;
  width: 250px;
  bottom: 0px;
  box-shadow: 0 15px 25px 1px rgba(0, 0, 0, 0.1);
  transform: translate3d(-100%, 0, 0);
}
.menu-box-right {
  right: 0px;
  transform: translateX(100%);
  top: 0px !important;
  top: constant(safe-area-inset-top) !important;
  top: env(safe-area-inset-top) !important;
  bottom: 0px;
  box-shadow: 0 15px 25px 1px rgba(0, 0, 0, 0.1);
  width: 250px;
}
.menu-box-top {
  transform: translate3d(0, -115%, 0);
  top: 0px;
  left: 0px;
  right: 0px;
  height: 300px;
}
.menu-box-bottom {
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 300px;
  transform: translate3d(-50%, 110%, 0);
}
.menu-box-bottom.menu-active {
  transform: translate3d(0%, 0, 0);
}
@media (min-width: 500px) {
  .menu-box-bottom {
    /* center of screen */
    left: 50% !important;
    transform: translate3d(-50%, 110%, 0);
    width: 400px;
  }
  .menu-box-bottom.menu-active {
    /* center of screen */
    left: 50% !important;
    transform: translate3d(-50%, 0, 0);
    width: 400px;
  }
}

.menu-box-modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0, 0);
  opacity: 0;
  pointer-events: none;
  transition: all 300ms ease;
}
.menu-box-top.menu-box-detached {
  top: 10px;
  left: 10px;
  right: 10px;
}
.menu-box-bottom.menu-box-detached {
  bottom: 20px;
  left: 10px;
  right: 10px;
}
.menu-box-left.menu-box-detached {
  top: 10px !important;
  bottom: 10px;
  top: calc(0px + (constant(safe-area-inset-top)) * 0.75) !important;
  top: calc(0px + (env(safe-area-inset-top)) * 0.75) !important;
  bottom: calc(0px + (constant(safe-area-inset-bottom)) * 0.75) !important;
  bottom: calc(0px + (env(safe-area-inset-bottom)) * 0.75) !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.menu-box-right.menu-box-detached {
  top: 10px !important;
  bottom: 10px;
  top: calc(0px + (constant(safe-area-inset-top)) * 0.75) !important;
  top: calc(0px + (env(safe-area-inset-top)) * 0.75) !important;
  bottom: calc(0px + (constant(safe-area-inset-bottom)) * 0.75) !important;
  bottom: calc(0px + (env(safe-area-inset-bottom)) * 0.75) !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.menu-box-left.menu-active {
  transform: translate3d(0%, 0, 0) !important;
}
.menu-box-right.menu-active {
  transform: translate3d(0%, 0, 0) !important;
}
.menu-box-top.menu-active {
  transform: translate3d(0%, 0, 0) !important;
}
.is-on-homescreen .menu-box-top.menu-active {
  transform: translate3d(0%, 40px, 0) !important;
}

.menu-box-modal.menu-active {
  transition: all 300ms ease;
  pointer-events: all !important;
  opacity: 1 !important;
  transform: translate(-50%, -50%) scale(1, 1) !important;
}
.menu-hider.menu-active {
  transition: all 300ms ease;
  opacity: 1;
  pointer-events: all;
}
.menu-hider.menu-active.menu-active-clear {
  background-color: rgba(0, 0, 0, 0) !important;
}
.menu-box-left[data-menu-effect="menu-reveal"] {
  opacity: 0;
  pointer-events: none;
  transform: translate(0px) !important;
  z-index: 96;
}
.menu-box-right[data-menu-effect="menu-reveal"] {
  opacity: 0;
  pointer-events: none;
  transform: translate(0px) !important;
  z-index: 96;
}
.menu-active.menu {
  opacity: 1 !important;
  transition: all 300ms ease;
  transition-delay: 70ms;
  pointer-events: all !important;
}
#menu-update {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  width: 310px;
  height: 430px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  border-radius: 10px;
  transition: all 300ms ease;
}
.menu-active {
  opacity: 1 !important;
}
.menu-active-no-click {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: all !important;
}
#menu-install-pwa-ios {
  overflow: visible !important;
}
.fa-ios-arrow {
  transform: translateY(4px);
}
.theme-light .fa-ios-arrow {
  color: #fff !important;
}
.menu-header {
  display: flex;
  margin-bottom: 25px;
}
.menu-header a {
  flex: 1 1 auto;
  text-align: center;
  line-height: 50px;
  border: solid 1px rgba(0, 0, 0, 0.06);
}
.menu-items {
  margin-bottom: 40px;
}
.menu-items a {
  display: block;
  line-height: 50px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
}
.menu-items a:last-child {
  border-bottom: none;
}
.menu-items a i:first-child {
  position: absolute;
  width: 55px;
  height: 50px;
  line-height: 51px;
  text-align: center;
  font-size: 14px;
}
.menu-items a svg {
  margin-top: 17px;
  position: absolute;
  margin-left: 20px;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
}
.menu-items span {
  color: #1f1f1f;
  padding-left: 60px;
  line-height: 50px;
  font-size: 13px;
  transition: all 250ms ease;
}
.submenu {
  overflow: hidden !important;
  backface-visibility: hidden !important;
  transform: Translate3d(0px, 0px, 0px);
}
.menu-items i:last-child {
  color: #434343;
  opacity: 0.3;
  position: absolute;
  right: 5px;
  transform: scale(0.7, 0.7);
  text-align: center;
  line-height: 50px;
  width: 50px;
  transition: all 250ms ease;
  font-size: 8px !important;
}
.menu-items em {
  position: absolute;
  right: 15px;
  line-height: 14px;
  font-size: 9px;
  padding: 2px 6px;
  text-align: center;
  margin-top: 18px;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  z-index: 2;
  border-radius: 4px;
}
.menu-items strong {
  position: absolute;
  right: 21px;
  width: 19px;
  height: 19px;
  line-height: 14px;
  font-size: 10px;
  text-align: center;
  margin-top: 18px;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  z-index: 2;
  border-radius: 11px;
}
.nav-item-active span {
  opacity: 1 !important;
  font-size: 13px;
  font-weight: 700 !important;
  transition: all 250ms ease;
}
.submenu .nav-item-active span {
  font-size: 13px !important;
}
.nav-item-active .fa-circle {
  opacity: 1 !important;
  transform: scale(1, 1) !important;
  color: #8cc152 !important;
}
.submenu {
  display: none;
}
.submenu a:first-child {
  padding-top: 10px;
}
.submenu a {
  margin-top: -1px;
  border: none;
}
.submenu a i {
  transform: scale(0.7, 0.7);
}
.submenu a span {
  margin-left: 0px;
  font-size: 12px;
  opacity: 0.7;
}
.submenu a:last-child {
  padding-bottom: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.card-bg {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 0;
}
.header-card {
  z-index: -1;
  top: 0px;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
}
.footer-card {
  z-index: -1;
  bottom: -60px;
  margin-bottom: 0px !important;
  position: absolute;
  left: -8px;
  right: -8px;
  overflow: hidden;
}
.header-card.shape-rounded {
  border-bottom-left-radius: 45px;
  border-bottom-right-radius: 45px;
}
.footer-card.shape-rounded {
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}
.header-card.shape-cut-left {
  width: 200%;
  transform: skew(0deg, 7deg) !important;
}
.header-card.shape-cut-right {
  margin-top: -40px;
  width: 110%;
  transform: skew(0deg, -7deg) !important;
}
.footer-card.shape-cut-left {
  width: 180%;
  transform: skew(0deg, 3deg) !important;
}
.footer-card.shape-cut-right {
  margin-top: -40px;
  width: 180%;
  transform: skew(0deg, -3deg) !important;
}
.page-title {
  margin: 20px 20px 30px 20px;
}
.greeting-text {
  display: flex !important;
}
.page-title-large .greeting-text {
  font-size: 22px !important;
  line-height: 30px;
  color: #fff;
  font-weight: 600 !important;
}
.page-title-small [data-menu] {
  font-weight: 600 !important;
  background-size: cover;
  border-radius: 40px;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 20px;
  margin-top: -40px;
  z-index: 98;
}
.page-title-small h2 {
  color: #fff;
  font-weight: 600 !important;
  padding-top: 10px;
  font-size: 24px !important;
}
.page-title-small h2 a {
  color: #fff;
  font-weight: 600 !important;
  padding-right: 20px;
  font-size: 19px;
}
.page-title-large [data-menu] {
  background-size: cover;
  border-radius: 50px;
  position: absolute;
  width: 50px;
  height: 50px;
  right: 20px;
  margin-top: -60px;
  z-index: 98;
}
.rounded-0 {
  border-radius: 0px !important;
}
.rounded-xs {
  border-radius: 5px !important;
}
.rounded-s {
  border-radius: 8px !important;
}
.rounded-sm {
  border-radius: 10px !important;
}
.rounded-m {
  border-radius: 15px !important;
}
.rounded-l {
  border-radius: 30px !important;
}
.rounded-xl {
  border-radius: 50px !important;
}
.shadow-none {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.shadow-0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.shadow-xs {
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.04) !important;
}
.shadow-s {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1) !important;
}
.shadow-m {
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08) !important;
}
.shadow-l {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.09) !important;
}
.shadow-xl {
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
}
.line-height-xs {
  line-height: 14px !important;
}
.line-height-s {
  line-height: 17px !important;
}
.line-height-m {
  line-height: 24px !important;
}
.line-height-l {
  line-height: 26px !important;
}
.line-height-xl {
  line-height: 37px !important;
}
.text-shadow-s {
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.4);
}
.text-shadow-l {
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
}
.boxed-text-xl,
.boxed-text-l,
.boxed-text-m,
.boxed-text-s {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.boxed-text-xl {
  width: 90%;
  max-width: 340px;
}
.boxed-text-l {
  width: 85%;
  max-width: 300px;
}
.boxed-text-m {
  width: 80%;
  max-width: 300px;
}
.boxed-text-s {
  width: 70%;
  max-width: 260px;
}
.font-200 {
  font-weight: 200;
}
.font-300 {
  font-weight: 300;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}
.font-800 {
  font-weight: 800;
}
.font-900 {
  font-weight: 900;
}
.opacity-10 {
  opacity: 0.1;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-85 {
  opacity: 0.85;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-95 {
  opacity: 0.95;
}
.font-8 {
  font-size: 8px !important;
}
.font-9 {
  font-size: 9px !important;
}
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-17 {
  font-size: 17px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-19 {
  font-size: 19px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-21 {
  font-size: 21px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-23 {
  font-size: 23px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-25 {
  font-size: 25px !important;
}
.font-26 {
  font-size: 26px !important;
}
.font-27 {
  font-size: 27px !important;
}
.font-28 {
  font-size: 28px !important;
}
.font-29 {
  font-size: 29px !important;
}
.font-30 {
  font-size: 30px !important;
}
.font-31 {
  font-size: 31px !important;
}
.font-32 {
  font-size: 32px !important;
}
.font-33 {
  font-size: 33px !important;
}
.font-34 {
  font-size: 34px !important;
}
.font-35 {
  font-size: 35px !important;
}
.font-36 {
  font-size: 36px !important;
}
.font-37 {
  font-size: 37px !important;
}
.font-38 {
  font-size: 38px !important;
}
.font-39 {
  font-size: 39px !important;
}
.font-40 {
  font-size: 40px !important;
}
@keyframes scale-animation {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.05, 1.05);
  }
  0% {
    transform: scale(1, 1);
  }
}
.scale-box {
  animation: scale-animation 1.5s;
  animation-iteration-count: infinite;
}
@keyframes scale-icon {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  0% {
    transform: scale(1, 1);
  }
}
.scale-icon {
  animation: scale-icon 1.5s;
  animation-iteration-count: infinite;
}
::-webkit-scrollbar {
  display: none;
}
img.mx-auto {
  display: block;
}
.no-click {
  pointer-events: none !important;
}
.no-outline {
  outline: none !important;
}
#preloader {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99999;
  transition: all 150ms ease;
}
#preloader .spinner-border {
  width: 50px;
  height: 50px;
  border-width: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
}
.preloader-hide {
  opacity: 0;
  pointer-events: none;
}
.theme-light #preloader {
  background-color: #fff;
}
.theme-dark #preloader {
  background-color: #0f1117;
}
.btn {
  transition: all 300ms ease;
}
.btn:hover {
  opacity: 0.9;
}
.btn-full {
  display: block;
}
.btn-xxs,
.btn-xs,
.btn-s,
.btn-sm,
.btn-m,
.btn-l {
  font-size: 12px !important;
}
.btn-xxs {
  padding: 6px 10px !important;
}
.btn-xs {
  padding: 7px 14px !important;
}
.btn-s {
  padding: 8px 18px !important;
}
.btn-sm {
  padding: 10px 18px !important;
}
.btn-m {
  padding: 12px 20px !important;
}
.btn-l {
  padding: 14px 26px !important;
}
.btn-xl {
  padding: 15px 30px !important;
  font-size: 15px !important;
}
.btn-xxl {
  padding: 17px 34px !important;
  font-size: 17px !important;
}
.btn-uppercase {
  text-transform: uppercase;
}
.btn-center-xs {
  width: 100px;
  margin: 0 auto;
  display: block;
}
.btn-center-s {
  width: 120px;
  margin: 0 auto;
  display: block;
}
.btn-center-m {
  width: 160px;
  margin: 0 auto;
  display: block;
}
.btn-center-l {
  width: 220px;
  margin: 0 auto;
  display: block;
}
.btn-center-xl {
  width: 250px;
  margin: 0 auto;
  display: block;
}
.btn.bg-highlight {
  color: #fff;
}
.btn-3d {
  transition: all 150ms ease;
  border: none;
  border-bottom: solid 4px;
}
.btn-3d:hover {
  border-width: 2px !important;
}
.btn:focus {
  outline: none !important;
}
.btn-margins {
  margin-left: 17px;
  margin-right: 17px;
  margin-bottom: 30px;
}
.under-slider-btn {
  z-index: 50;
  position: relative;
  margin-top: -20px;
}
.btn-icon:hover {
  color: #fff;
}
.btn-icon {
  padding-left: 50px !important;
  overflow: hidden;
  position: relative;
}
.btn-border {
  border-style: solid !important;
  border-width: 1px !important;
}
.btn-icon i {
  position: absolute;
  left: 0px;
  top: 0px;
  line-height: 43px;
  width: 40px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.icon:hover {
  color: #fff;
}
.icon {
  display: inline-block;
  position: relative;
  text-align: center;
}
.icon-xxs i {
  width: 32px;
  line-height: 32px;
  font-size: 13px;
}
.icon-xs i {
  width: 38px;
  line-height: 38px;
  font-size: 14px;
}
.icon-s i {
  width: 40px;
  line-height: 40px;
  font-size: 15px;
}
.icon-m i {
  width: 45px;
  line-height: 45px;
  font-size: 16px;
}
.icon-l i {
  width: 51px;
  line-height: 51px;
  font-size: 17px;
}
.icon-xl i {
  width: 55px;
  line-height: 55px;
  font-size: 18px;
}
.icon-xxl i {
  width: 60px;
  line-height: 60px;
  font-size: 19px;
}
.icon-center {
  display: block;
  margin: 0 auto;
}
.icon-border {
  border: solid 1px #cacaca;
}
.icon-size {
  width: 65px;
  text-align: center;
  display: block;
  margin-right: 15px;
}
.icon-user {
  margin-left: -15px;
  width: 55px;
  text-align: center;
}
.accordion * {
  outline: 0 !important;
  box-shadow: 0px !important;
  box-shadow: none !important;
}
.accordion .btn:first-child {
  width: 100%;
}
.accordion .card {
  transition: all 0ms ease !important;
}
.accordion button {
  transition: all 0ms ease !important;
}
.accordion [data-card-height] button {
  width: 100%;
}
.btn.accordion-btn {
  text-align: left !important;
  padding: 15px 17px;
  font-size: 13px;
  font-weight: 700;
}
.accordion-icon {
  float: right;
  transition: all 250ms ease;
  margin-top: 5px;
}
.offline-message {
  position: fixed;
  transform: translateY(-200%);
  padding-bottom: 0px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  transition: all 300ms ease;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 9999;
  line-height: 55px;
}
.online-message {
  position: fixed;
  transform: translateY(-200%);
  padding-bottom: 0px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  transition: all 300ms ease;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 9999;
  line-height: 55px;
}
.is-on-homescreen .offline-message {
  padding-top: 30px !important;
}
.is-on-homescreen .online-message {
  padding-top: 30px !important;
}
.offline-message-active {
  transform: translateY(0%);
}
.online-message-active {
  transform: translateY(0%);
}
.add-to-home-visible {
  opacity: 1 !important;
  pointer-events: all !important;
}
.add-to-home {
  position: fixed;
  background-color: #000;
  z-index: 99;
  left: 15px;
  right: 15px;
  border-radius: 10px;
  height: 80px;
  opacity: 0;
  pointer-events: none;
  transition: all 250ms ease;
}
.add-to-home .close-add-to-home {
  pointer-events: none;
}
.add-to-home h1 {
  position: absolute;
  padding-left: 75px;
  padding-top: 8px;
  color: #fff;
  font-size: 13px;
}
.add-to-home p {
  color: #ced4da;
  position: absolute;
  font-size: 11px;
  padding-right: 20px;
  padding-left: 75px;
  padding-top: 35px;
  line-height: 14px;
}
.add-to-home img {
  width: 45px;
  margin-top: 17px;
  margin-left: 15px;
}
.add-to-home-ios {
  bottom: 25px;
}
.add-to-home-ios i {
  width: 40px;
  text-align: center;
  position: absolute;
  font-size: 30px;
  left: 50%;
  bottom: -18px !important;
  margin-left: -21px;
  transform: rotate(180deg);
  color: #000;
  display: block;
}
.add-to-home-ios .close-add-to-home {
  position: absolute;
  right: 13px;
  top: 13px;
  transform: scale(0.5, 0.5);
}
.add-to-home-android {
  top: 20px;
}
.add-to-home-android i {
  width: 40px;
  text-align: center;
  position: absolute;
  top: -18px;
  right: 15px;
  font-size: 30px;
  margin-left: -20px;
  color: #000;
  display: block;
}
.add-to-home-android .close-add-to-home {
  position: absolute;
  right: 5px;
  top: 20px;
  transform: scale(0.5, 0.5);
}
.ad-300x50 {
  margin: 0 auto;
  width: 300px;
  height: 50px;
}
.ad-300x250 {
  margin: 0 auto;
  width: 300px;
  height: 250px;
}
.ad-300x50-fixed {
  left: 50%;
  margin-left: -150px;
  bottom: 60px;
  bottom: calc(65px + (constant(safe-area-inset-bottom)) * 1.1) !important;
  bottom: calc(65px + (env(safe-area-inset-bottom)) * 1.1) !important;
  position: fixed;
  z-index: 90;
}
.ad-time-close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  line-height: 27px;
  text-align: center;
  border: solid 2px;
  border-radius: 30px;
}
.scroll-ad-visible {
  transform: translateY(0%) !important;
}
.is-on-homescreen .scroll-ad {
  bottom: 110px;
}
.scroll-ad {
  position: fixed;
  bottom: 75px;
  left: 10px;
  right: 10px;
  background-color: #fff;
  z-index: 93;
  height: 80px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  transform: translateY(150%);
  transition: all 300ms ease;
}
.scroll-ad img {
  width: 60px;
  position: absolute;
  margin: 10px;
  border-radius: 10px;
}
.scroll-ad h1 {
  display: block;
  font-size: 18px;
  padding: 7px 0px 0px 80px;
  margin-bottom: -3px;
}
.scroll-ad em {
  padding: 0px 85px 0px 80px;
  font-style: normal;
  font-size: 11px;
  opacity: 0.7;
  display: block;
  line-height: 15px;
}
.scroll-ad a {
  width: 60px;
  font-size: 9px;
  right: 15px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: absolute;
  top: 50%;
  border-radius: 5px;
  transform: translateY(-50%);
}
.alert h4 {
  padding-left: 47px;
  margin-bottom: -5px;
}
.alert button {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  text-align: center;
}
.alert button span {
  background-color: rgba(0, 0, 0, 0);
}
.alert button:active {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}
.alert button:focus {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}
.alert span {
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: -1px;
  top: -1px;
  bottom: -1px;
  width: 47px;
}
.alert span i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.alert strong {
  padding-left: 47px;
  font-weight: 400;
}
.notification {
  position: fixed;
  top: 10px;
  left: 10px;
  right: 10px;
  z-index: 101;
  border-radius: 10px;
  opacity: 0.98;
  transform: translateY(-120%);
  transition: all 250ms ease;
}
.notification .notification-icon {
  padding: 5px 5px;
  display: block;
  background-color: #343a40;
}
.notification .notification-icon i:first-child {
  margin: 0px 5px 0px 10px;
  font-size: 10px;
}
.notification .notification-icon i:last-child {
  position: absolute;
  right: 0px;
  line-height: 30px;
  width: 40px;
  text-align: center;
}
.notification .notification-icon em {
  font-size: 11px;
  text-transform: uppercase;
  font-style: normal;
}
.notification h1 {
  margin: 10px 15px -7px 15px;
}
.notification p {
  line-height: 15px;
  margin: 5px 15px 18px 15px;
  color: #fff;
}
.notification.show {
  transform: translateY(0%) !important;
}
.is-ios.is-on-homescreen .notification.show {
  transform: translateY(30px) !important;
}
.notification-android {
  left: 5px;
  right: 5px;
  top: 5px;
  border-radius: 3px;
}
.notification-android i {
  position: absolute;
  top: 50%;
  transform: translateY(-55%);
  width: 35px;
  margin-left: 15px;
  line-height: 35px;
  border-radius: 35px;
  text-align: center;
  font-size: 13px;
}
.notification-android h1 {
  padding-left: 55px;
}
.notification-android p {
  padding-right: 60px;
  padding-left: 55px;
  font-size: 12px;
}
.notification-android strong {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
}
.notification-android .notification-buttons {
  width: 100%;
  margin-left: 70px;
  padding-top: 5px;
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}
.notification-android .notification-buttons i {
  opacity: 0.6;
  font-size: 11px;
  position: relative;
  float: left;
  margin: 0px 10px 0px 0px;
  width: auto;
  transform: translateY(-22px);
}
.notification-android a {
  font-size: 11px;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  font-weight: 500;
}
.notification-material {
  left: 0px;
  right: 0px;
  top: 0px;
  border-radius: 0px;
}
.notification-material strong {
  font-size: 10px;
  font-weight: 400;
}
.notification-material em {
  text-transform: none !important;
}
.notification-material .notification-icon {
  background-color: rgba(0, 0, 0, 0) !important;
  margin-bottom: -15px;
}
.notification-material img {
  position: absolute;
  width: 50px;
  border-radius: 50px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.notification-material p {
  padding-right: 50px;
}
.content .back-to-top-badge {
  transform: translate(0, 0) !important;
  width: 130px;
  margin: 10px auto 20px auto !important;
  left: auto;
  bottom: auto;
  display: block;
  position: relative !important;
}
.content .back-to-top-icon {
  transform: scale(1, 1) translate(0, 0px) !important;
  margin: 0px auto 30px auto;
  right: auto;
  bottom: auto;
  display: block;
  position: relative !important;
}
.back-to-top-badge {
  position: fixed;
  z-index: 90;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, 70px);
  display: inline-block;
  padding: 5px 20px;
  border-radius: 35px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  transition: all 300ms ease;
}
.back-to-top-badge i {
  padding: 0px 10px 0px 0px;
}
.back-to-top-icon {
  position: fixed;
  z-index: 9999;
  bottom: 70px;
  right: 15px;
  display: inline-block;
  width: 35px;
  line-height: 35px;
  border-radius: 35px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  transform: scale(0, 0);
  transition: all 300ms ease;
}
.back-to-top-icon span {
  display: none;
}
.back-to-top-badge.back-to-top-visible {
  transform: translate(-50%, 0px);
}
.back-to-top-icon.back-to-top-visible {
  transform: scale(1, 1);
}
.back-to-top-icon-square {
  border-radius: 0px;
}
.back-to-top-icon-round {
  border-radius: 5px;
}
.back-to-top-icon-circle {
  border-radius: 40px;
}
.row {
  margin-bottom: 30px;
}
.card {
  -webkit-transform: translate3d(0, 0, 0);
  background-size: cover;
  border: 0px !important;
  max-height: 150000px;
  margin-bottom: 30px;
  background-position: center center !important;
}
.cover-slider .card {
  margin-bottom: 0px !important;
}
[data-card-height="cover"].card {
  margin-bottom: 0px !important;
}
.card-style {
  overflow: hidden;
  margin: 0px 16px 30px 16px;
  border-radius: 15px;
  border: none;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1) !important;
}
.card-style .card-body {
  padding: 15px;
}
.card-style .card-overlay {
  border-radius: 15px;
}
.card-body {
  z-index: 2;
}
.card-header {
  z-index: 2;
}
.card-footer {
  z-index: 2;
}
.card-top {
  z-index: 2;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}
.card-bottom {
  z-index: 2;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.card-center {
  z-index: 2;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.card-overlay {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  position: absolute;
  z-index: 1;
}
.card-image {
  display: block;
  width: 100%;
  min-height: 250px;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.card-image img {
  transition: all 450ms ease;
}
.card-overflow {
  overflow: visible !important;
  z-index: 20;
}
[data-card-height] p {
  padding-bottom: 0px;
}
.card-scale-image {
  transform: scale(1.05, 1.05) !important;
}
.card-rotate-image {
  transform: rotate(5deg) scale(1.2, 1.2) !important;
}
.card-grayscale-image {
  filter: grayscale(100%);
}
.card-blur-image {
  filter: blur(5px);
}
.card-hide-image {
  opacity: 0 !important;
}
.card-hide div {
  transition: all 300ms ease;
}
.card-hide * {
  transition: all 300ms ease;
}
.polaroid-effect {
  background-color: #fff;
  padding: 5px;
  border: solid 1px rgba(0, 0, 0, 0.1);
}
.card-scale * {
  transition: all 300ms ease;
}
.card-rotate * {
  transition: all 300ms ease;
}
.card-grayscale * {
  transition: all 300ms ease;
}
.card-blur * {
  transition: all 300ms ease;
}
.card-overlay {
  background-position: center center;
  background-size: cover !important;
}
.card-overlay-infinite {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 2000px;
  height: 100%;
  z-index: 0;
  animation: infiniteBG 600s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* background-image: url(images/mountain.jpg); */
  background-size: cover;
  background-repeat: repeat;
  background-position: left bottom;
  transform: translateX(0px);
}
@keyframes infiniteBG {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-1400px);
  }
  100% {
    transform: translateX(0px);
  }
}
.card-bg {
  background-size: cover;
  background-position: center center;
}
.chip {
  display: inline-block;
  margin: 0 10px 15px 0;
  border-radius: 30px;
  padding-right: 15px;
  background-color: #e2e5ea !important;
}
.theme-dark .chip {
  background-color: #434a54 !important;
  color: #fff !important;
}
.chip * {
  font-size: 12px;
}
.chip span {
  line-height: 29px;
}
.chip i {
  width: 30px;
  line-height: 30px;
  border-radius: 30px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}
.chip img {
  display: inline;
  float: left;
  margin-right: 9px;
  border-radius: 30px;
  width: 30px;
  line-height: 30px;
}
.chip-small {
  transform: scale(1, 1);
}
.chip-medium {
  transform: scale(1.05, 1.05);
}
.chip-large {
  transform: scale(1.15, 1.15);
}
.divider {
  height: 1px;
  display: block;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}
.divider-margins {
  margin: 0px 17px 30px 17px;
}
.divider-icon {
  height: 1px;
  display: block;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 50px;
}
.divider-icon i {
  position: absolute;
  width: 50px;
  background-color: #f0f0f0;
  text-align: center;
  margin-top: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.content-boxed .divider-icon i {
  background-color: #f0f0f0;
}
.divider-small {
  height: 2px;
  width: 80px;
  margin: auto;
}
.custom-control * {
  cursor: pointer;
  user-select: none !important;
}
.switch-is-unchecked {
  display: none;
}
.switch-is-checked {
  display: block;
}
.scale-switch {
  transform: scale(0.9, 0.9);
}
.ios-switch {
  padding-left: 10px;
}
.ios-switch label::before {
  background-color: #e8e9ea;
  pointer-events: all;
  margin-top: -5px;
  margin-left: -1px;
  width: 58px !important;
  height: 32px !important;
  border-radius: 50px !important;
}
.ios-switch label::after {
  margin-top: -4px;
  z-index: 6;
  width: 30px !important;
  height: 30px !important;
  transition: all 250ms ease;
  border-radius: 50px !important;
  background-color: #fff;
  border: solid 1px rgba(152, 152, 152, 0.4);
  transition: all 250ms ease;
}
.ios-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(26px) !important;
}
.ios-input:checked ~ .custom-control-label::before {
  background-color: #8cc152 !important;
}
.ios-switch-icon label::before {
  background-color: #da4453 !important;
}
.ios-switch-icon i {
  line-height: 32px;
  pointer-events: none;
  z-index: 5;
  position: absolute;
  left: 10px;
}
.ios-switch-icon i:last-child {
  z-index: 5;
  position: absolute;
  margin-left: 29px;
}
.ios-switch-icon span {
  font-size: 9px;
  font-weight: 700;
  color: #fff;
  line-height: 32px;
  pointer-events: none;
  z-index: 5;
  position: absolute;
  left: 10px;
}
.ios-switch-icon span:last-child {
  z-index: 5;
  position: absolute;
  margin-left: 24px;
}
.android-switch {
  padding-left: 10px;
}
.android-switch label::before {
  pointer-events: all;
  margin-top: 0px;
  margin-left: -2px;
  width: 53px !important;
  height: 17px !important;
  border-radius: 50px !important;
  background-color: #aab2bd;
}
.android-switch label::after {
  border: solid 1px rgba(0, 0, 0, 0.1) !important;
  margin-top: -6px;
  margin-left: -3px;
  width: 28px !important;
  height: 28px !important;
  background-color: #fff !important;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 50px !important;
  transition: all 250ms ease;
}
.android-input {
  transform: translateY(1px) !important;
}
.android-input:checked ~ .custom-control-label::after {
  background-color: #458be3 !important;
  border: none !important;
  -webkit-transform: translateX(30px) !important;
}
.android-input:checked ~ .custom-control-label::before {
  background-color: #458be3 !important;
}
[data-activate] {
  cursor: pointer;
  display: block;
  width: 100%;
}
.classic-switch input {
  display: none;
}
.classic-switch label::before {
  pointer-events: all;
  width: 40px !important;
  height: 40px !important;
  transform: translateX(11px);
  margin-top: -10px;
  border: none;
  background-color: rgba(0, 0, 0, 0) !important;
}
.classic-switch i {
  pointer-events: none;
  transition: all 250ms ease;
}
.classic-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(26px) !important;
}
.classic-input:checked ~ .fa-plus {
  color: #da4453 !important;
  transform: rotate(45deg);
  transition: all 250ms ease;
}
.classic-input:checked ~ .fa-angle-down {
  color: #da4453 !important;
  transform: rotate(180deg);
  transition: all 250ms ease;
}
.classic-input:checked ~ .fa-circle {
  color: #da4453 !important;
  transform: scale(1.3, 1.3);
  transition: all 250ms ease;
}
.fac {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  display: block;
  vertical-align: middle;
  font-size: 14px;
}
.fac label {
  margin: 0em 1em 0em 0em;
  padding-left: 1.5em;
  cursor: pointer;
  font-weight: inherit;
}
.fac > input + label:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 0;
  margin-top: 0.5px;
  transition: opacity 150ms ease-in-out;
}
.fac > input:checked + label:before {
  opacity: 1;
}
.fac > span::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 0;
  margin-top: 0.5px;
}
.fac input {
  display: none;
}
.checkboxes-demo .fac {
  display: block;
}
.fac-checkbox > input + label:before {
  opacity: 0;
}
.fac-checkbox > input:checked + label:before {
  opacity: 1;
}
.fac-checkbox > span::after {
  content: "\f45c";
  opacity: 1;
}
.fac-checkbox > input[type="checkbox"] + label:before {
  content: "\f00c";
  color: #fff !important;
  font-size: 10px !important;
  padding-left: 2px;
}
.fac-checkbox-round > input + label:before {
  opacity: 0;
}
.fac-checkbox-round > span::after {
  content: "\f111";
}
.fac-checkbox-round > input[type="checkbox"] + label:before {
  content: "\f00c";
  color: #fff !important;
  font-size: 8px !important;
  padding-left: 3px;
}
.fac-radio > input + label:before {
  opacity: 0;
}
.fac-radio > input:checked + label:before {
  opacity: 1;
}
.fac-radio > span::after {
  content: "\f111";
}
.fac-radio > input[type="radio"] + label:before {
  content: "\f00c";
  color: #fff !important;
  font-size: 10px !important;
  padding-left: 2px;
}
.fac-checkbox-o > input + label:before {
  opacity: 0;
}
.fac-checkbox-o > input:checked + label:before {
  opacity: 1;
}
.fac-checkbox-round-o > input + label:before {
  opacity: 0;
}
.fac-checkbox-round-o > input:checked + label:before {
  opacity: 1;
}
.fac-default > input + label:before {
  color: #adb5bd;
}
.fac-default > span::after {
  color: #adb5bd;
}
.fac-blue > input + label:before {
  color: #007bff;
}
.fac-blue > span::after {
  color: #007bff;
}
.fac-green > input + label:before {
  color: #28a745;
}
.fac-green > span::after {
  color: #28a745;
}
.fac-orange > input + label:before {
  color: #ffc107;
}
.fac-orange > span::after {
  color: #ffc107;
}
.fac-red > input + label:before {
  color: #dc3545;
}
.fac-red > span::after {
  color: #dc3545;
}
.fac-radio-full > span::after {
  content: "\f111";
  font-size: 12px !important;
}
.fac-radio-full > input[type="radio"] + label:before {
  content: "\00c";
}
.input-style-1 {
  position: relative;
}
.input-style-1 input {
  font-size: 13px;
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-bottom: 10px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
  transition: all 250ms ease;
}
.input-style-1 input:focus {
  border-bottom: solid 1px #000;
}
.input-style-1 textarea {
  font-size: 13px;
  display: block;
  width: 100%;
  height: 50px;
  line-height: 28px;
  padding-right: 15px;
  padding-top: 14px;
  margin-top: -5px;
  margin-bottom: 15px;
  background-color: rgba(0, 0, 0, 0);
  margin-left: 0px !important;
  padding-left: 0px !important;
  text-indent: 0px !important;
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
  transition: all 250ms ease;
}
.input-style-1 textarea:focus {
  line-height: 24px;
  height: 150px;
  transition: all 250ms ease;
}
.input-style-1 span {
  color: #007bff;
  font-weight: 500;
  position: absolute;
  font-size: 10px;
  margin-top: -3px;
  opacity: 0;
  transition: all 250ms ease;
}
.input-style-1 em {
  position: absolute;
  top: 14px;
  font-size: 10px;
  font-style: normal;
  right: 0px;
  color: #dee2e6;
}
.input-style-1 em i {
  font-size: 12px;
  width: 15px;
  text-align: center;
  margin-top: 7px;
  display: block;
}
.input-style-1 input[type="date"] {
  font-size: 13px;
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-bottom: 15px;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
  transition: all 250ms ease;
  color: #6c757d;
}
.input-style-1 input[type="date"]::-webkit-inner-spin-button {
  opacity: 0;
}
.input-style-1 input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.input-style-1 input[type="date"]::-webkit-clear-button {
  opacity: 0;
}
.input-style-1 select {
  color: #6c757d;
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  margin-bottom: 15px;
  height: 50px;
  line-height: 50px;
  border-radius: 0px;
  width: 100%;
  box-shadow: none;
  -webkit-appearance: none;
  background-color: rgba(0, 0, 0, 0);
  font-size: 14px;
}
.input-style-1 select:focus {
  border-bottom: solid 1px #000;
}
.input-style-1-active {
  opacity: 1 !important;
}
.input-style-1-inactive {
  color: #dee2e6 !important;
}
.input-style-1.has-icon .input-icon {
  position: absolute;
  z-index: 10;
  margin-top: 20px;
  margin-left: 0px;
}
.input-style-1.has-icon input {
  padding-left: 30px;
}
.input-style-1.has-icon span {
  margin-left: 25px;
}
.input-style-1.has-icon .input-style-1-active {
  color: #007bff !important;
  transform: translateX(-25px);
  transition: all 150ms ease;
}
input[type="range"] {
  -webkit-appearance: none;
  height: 20px;
  width: 100%;
  outline: none;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #f26b5e;
  position: relative;
  z-index: 3;
}
input[type="range"]::-webkit-slider-thumb:after {
  content: " ";
  height: 10px;
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 5px;
  background: #ff5b32;
  background: linear-gradient(to right, #f088fc 1%, #ac6cff 70%);
}
.input-style-2 {
  margin-bottom: 15px;
  position: relative;
}
.input-style-2 span {
  pointer-events: none;
  color: #000 !important;
  position: absolute;
  font-size: 13px;
  z-index: 1;
  height: 22px;
  line-height: 20px;
  padding: 2px 5px 2px 5px;
  top: 14px;
  left: 10px;
  right: 10px;
  background-color: #fff;
  transition: all 150ms ease;
}
.input-style-2 .input-style-1-active {
  color: #007bff !important;
  transform: translateY(-25px);
  transition: all 150ms ease;
}
.input-style-2 em {
  position: absolute;
  right: 10px;
  z-index: 2;
  font-size: 10px;
  font-style: normal;
  color: #dee2e6;
  line-height: 53px;
}
.input-style-2 em i {
  font-size: 12px !important;
  margin-top: 5px !important;
}
.input-style-2 input {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px !important;
  border-color: rgba(0, 0, 0, 0.1);
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0px 14px;
  font-size: 13px;
  -webkit-appearance: none;
}
.input-style-2 textarea {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px !important;
  display: block;
  border-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0px 14px;
  font-size: 13px;
  -webkit-appearance: none;
}
.input-style-2 select {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px !important;
  display: block;
  border-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0px 14px;
  font-size: 13px;
  -webkit-appearance: none;
}
.input-style-2 input[type="date"]::-webkit-inner-spin-button {
  opacity: 0;
}
.input-style-2 input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.input-style-2 input[type="date"]::-webkit-clear-button {
  opacity: 0;
}
span.input-style-1-active {
  opacity: 1;
  padding-right: 10px !important;
  right: auto !important;
}
.input-style-2.has-icon .input-icon {
  position: absolute;
  z-index: 10;
  margin-top: 20px;
  margin-left: 15px;
}
.input-style-2.has-icon input {
  padding-left: 40px;
}
.input-style-2.has-icon span {
  margin-left: 25px;
}
.input-style-2.has-icon .input-style-1-active {
  color: #007bff !important;
  transform: translate(-25px, -25px);
  transition: all 150ms ease;
}
.input-light i {
  color: #fff;
}
.input-light span {
  color: #fff !important;
}
.input-light em {
  color: #6c757d !important;
}
.input-light input {
  color: #fff !important;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1) !important;
}
.input-light input::placeholder {
  color: #fff;
}
.upload-file {
  text-indent: -999px;
  outline: none;
  width: 100%;
  height: 45px;
  color: rgba(0, 0, 0, 0) !important;
}
.upload-file-text {
  pointer-events: none;
  margin-top: -36px !important;
  text-align: center;
  color: #fff;
  height: 10px;
  text-transform: uppercase;
  font-weight: 900;
}
.file-data input[type="file"] {
  font-size: 0px !important;
}
input {
  font-size: 14px !important;
}
input::placeholder {
  font-size: 14px !important;
}
input:focus {
  outline: none;
}
select:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
button:focus {
  outline: none;
}
.tooltip {
  font-size: 13px;
}
code {
  font-size: 11px;
  line-height: 22px;
}
.ios-slider {
  height: 40px !important;
  background: linear-gradient(to right, #5d9cec 0%, #5d9cec 100%);
  background-size: 100% 3px;
  background-position: center;
  background-repeat: no-repeat;
}
.ios-slider::-webkit-slider-thumb {
  background-color: #fff !important;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  height: 25px !important;
  width: 25px !important;
  box-shadow: -3px 3px 10px 1px rgba(0, 0, 0, 0.2) !important;
}
.material-slider {
  height: 40px !important;
  background: linear-gradient(to right, #ccd1d9 0%, #ccd1d9 100%);
  background-size: 100% 18px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0px 3px 0px 3px;
}
.material-slider::-webkit-slider-thumb {
  background-color: #1f1f1f !important;
  border: solid 1px rgba(0, 0, 0, 0.1);
  height: 10px !important;
  width: 30px !important;
}
.classic-slider {
  height: 20px !important;
  background-color: #fff;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 30px !important;
  background-size: 100% 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  padding: 0px 3px 0px 3px;
  -webkit-box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.1);
}
.classic-slider::-webkit-slider-thumb {
  background-color: #007bff !important;
  border: solid 1px rgba(0, 0, 0, 0);
  height: 16px !important;
  border-radius: 20px;
  width: 16px !important;
  box-shadow: -3px 3px 10px 1px rgba(0, 0, 0, 0.2) !important;
}
.range-slider-icons {
  padding: 0px 30px 0px 30px;
}
.range-slider.range-slider-icons i {
  position: absolute;
  top: 0px;
  height: 44px;
  line-height: 44px;
  text-align: center;
}
.range-slider {
  position: relative;
}
.range-slider .fa-range-icon-1 {
  left: 0px;
}
.range-slider .fa-range-icon-2 {
  right: 0px;
}
.list-custom-small {
  line-height: 50px;
}
.list-custom-small .badge {
  position: absolute;
  right: 15px;
  margin-top: 16px !important;
  font-size: 9px;
  padding: 5px 8px 5px 8px;
  font-weight: 700;
}
.list-custom-small a {
  color: #1f1f1f;
  font-weight: 500;
  font-size: 13px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.list-custom-small a i:first-child {
  margin-left: 0px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
  text-align: center;
  float: left;
  margin-right: 10px;
}
.list-custom-small a i:last-child {
  float: right;
  font-size: 10px;
  opacity: 0.5;
}
.list-custom-small i {
  line-height: 50px;
}
.list-custom-small .custom-control {
  z-index: 10;
  position: absolute;
  right: 40px;
  margin-top: -40px;
}
.list-custom-small .custom-control input {
  transform: translateY(-5px) !important;
}
.menu .list-custom-small a i:first-child {
  margin-left: 6px;
}
.list-custom-large a {
  line-height: 70px;
  color: #1f1f1f;
  font-weight: 500;
  font-size: 13px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.list-custom-large a b:not(.badge) {
  font-size: 14px;
  position: absolute;
  margin-top: -6px;
}
.list-custom-large a strong {
  font-size: 7px;
  position: absolute;
  font-size: 11px;
  margin-top: 10px;
  color: #adb5bd;
  font-weight: 500;
}
.list-custom-large a i:first-child {
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-top: 21px;
  text-align: center;
  float: left;
  margin-right: 15px;
}
.list-custom-large a i:last-child {
  float: right;
  font-size: 10px;
}
.list-custom-large .badge {
  position: absolute;
  right: 10px;
  margin-top: 30px;
  font-size: 9px;
  padding: 5px 8px 5px 8px;
  font-weight: 700;
}
.list-custom-large i {
  line-height: 74px;
}
.list-custom-large .custom-control {
  z-index: 10;
  position: absolute;
  right: 40px;
  margin-top: 22px;
}
.list-custom-large .custom-control input {
  transform: translateY(-12px) !important;
}
.list-boxes a {
  line-height: 60px;
  margin-bottom: 10px;
  color: #1f1f1f;
  font-weight: 500;
  font-size: 13px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.list-boxes a span:not(.badge) {
  padding-left: 10px;
  font-size: 14px;
  position: absolute;
  margin-top: -4px;
}
.list-boxes a strong {
  padding-left: 10px;
  font-size: 7px;
  position: absolute;
  font-size: 11px;
  margin-top: 10px;
  color: #6c757d;
  font-weight: 500;
}
.list-boxes a i:first-child {
  width: 40px;
  float: left;
  padding-left: 15px;
}
.list-boxes a i:last-child {
  float: right;
  font-size: 16px;
  margin-top: -5px;
  margin-right: 15px;
}
.list-boxes a u {
  position: absolute;
  right: 0px;
  margin-right: 9px;
  width: 60px;
  font-size: 7px;
  text-align: center;
  margin-top: 13px;
  text-decoration: none;
}
.list-boxes .badge {
  position: absolute;
  right: 10px;
  margin-top: 30px;
  font-size: 9px;
  padding: 5px 8px 5px 8px;
  font-weight: 700;
}
.list-boxes i {
  line-height: 64px;
}
.short-border {
  margin-left: 45px;
}
.short-border i:first-child {
  margin-left: -45px;
  margin-top: 23px;
}
.list-group .badge {
  z-index: 2;
}
.list-icon-0 a i:first-child {
  margin-left: -3px !important;
}
.icon-list {
  margin-left: 0px;
  padding-left: 0px;
}
.icon-list li {
  list-style: none;
}
.icon-list li i {
  padding-right: 10px;
}
.badge-text {
  margin-top: 13px !important;
  transform: translateY(-3px);
  padding-right: 20px !important;
}
.pricing-1 {
  max-width: 280px;
  margin: 0 auto 30px auto;
  border: solid 1px rgba(0, 0, 0, 0.05);
  padding: 20px 0px 30px 0px;
}
.pricing-1 * {
  list-style: none;
}
.pricing-1 .pricing-icon {
  font-size: 50px;
}
.pricing-1 .pricing-title {
  font-size: 32px;
  padding: 20px 0px 0px 0px;
}
.pricing-1 .pricing-value {
  font-size: 40px;
  font-weight: 300;
  padding: 20px 0px 0px 0px;
}
.pricing-1 .pricing-value sup {
  font-size: 12px;
  top: -18px;
}
.pricing-1 .pricing-value sup:first-child {
  padding-right: 5px;
  top: -13px;
  font-size: 20px;
}
.pricing-1 .pricing-subtitle {
  opacity: 0.6;
  font-size: 11px;
  line-height: 18px;
  font-weight: 300;
  padding: 0px 0px 10px 0px;
}
.pricing-1 .pricing-list {
  padding: 0px;
}
.pricing-1 .pricing-list li {
  line-height: 40px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-weight: 500;
}
.pricing-1 .pricing-list li:last-child {
  border-bottom: none;
  margin-bottom: -20px;
}
.pricing-2 {
  max-width: 280px;
  margin: 0 auto 30px auto;
  border: solid 1px rgba(0, 0, 0, 0.05);
  padding: 0px 0px 10px 0px;
  overflow: hidden;
}
.pricing-2 * {
  list-style: none;
}
.pricing-2 .pricing-icon {
  font-size: 50px;
  padding: 10px 0px 10px 0px;
}
.pricing-2 .pricing-title {
  font-size: 25px;
  padding: 15px 0px 15px 0px;
  margin: -10px 0px 20px 0px;
}
.pricing-2 .pricing-overtitle {
  font-size: 25px;
  padding: 15px 0px 15px 0px;
  margin: -10px 0px 0px 0px;
}
.pricing-2 .pricing-value {
  font-size: 43px;
  padding: 15px 0px 0px 0px;
}
.pricing-2 .pricing-value sup {
  font-size: 12px;
  top: -18px;
}
.pricing-2 .pricing-value sup:first-child {
  padding-right: 5px;
  top: -13px;
  font-size: 20px;
}
.pricing-2 .pricing-subtitle {
  font-size: 11px;
  line-height: 18px;
  font-weight: 400;
  padding: 0px 0px 0px 0px;
}
.pricing-2 .pricing-list {
  padding: 0px;
}
.pricing-2 .pricing-list li {
  line-height: 40px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.04);
  font-size: 14px;
  font-weight: 500;
}
.pricing-2 .pricing-list li:last-child {
  border-bottom: none;
}
.pricing-3 {
  max-width: 280px;
  margin: 0 auto 30px auto;
  border: solid 1px rgba(0, 0, 0, 0.05);
  padding: 0px 0px 10px 0px;
  overflow: hidden;
}
.pricing-3 * {
  list-style: none;
}
.pricing-3 .btn {
  max-width: 90%;
  margin: -10px auto 10px auto;
}
.pricing-3 .pricing-icon {
  font-size: 50px;
}
.pricing-3 .pricing-title {
  font-size: 28px;
  padding: 30px 0px 0px 20px;
}
.pricing-3 .pricing-value {
  text-align: center;
  font-size: 40px;
  font-weight: 300;
  padding: 20px 0px 20px 0px;
}
.pricing-3 .pricing-value sup {
  font-size: 12px;
  top: -18px;
}
.pricing-3 .pricing-value sup:first-child {
  padding-right: 5px;
  top: -13px;
  font-size: 20px;
}
.pricing-3 .pricing-subtitle {
  opacity: 0.6;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  padding: 0px 0px 30px 20px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
.pricing-3 .pricing-list {
  padding: 0px;
}
.pricing-3 .pricing-list i {
  padding-right: 20px;
  width: 30px;
}
.pricing-3 .pricing-list li {
  line-height: 50px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-weight: 500;
  padding: 0px 0px 0px 20px;
}
.pricing-4 {
  max-width: 280px;
  margin: 0 auto 30px auto;
  border: solid 1px rgba(0, 0, 0, 0.05);
  padding: 0px 0px 10px 0px;
  overflow: hidden;
}
.pricing-4 * {
  list-style: none;
}
.pricing-4 .pricing-title {
  font-size: 18px;
  padding: 15px 0px 15px 0px;
  margin: 0px 0px 0px 0px;
}
.pricing-4 .pricing-value {
  font-size: 43px;
  padding: 30px 0px 20px 0px;
}
.pricing-4 .pricing-value sup {
  font-size: 12px;
  top: -18px;
}
.pricing-4 .pricing-value sup:first-child {
  padding-right: 5px;
  top: -13px;
  font-size: 20px;
}
.pricing-4 .pricing-subtitle {
  font-size: 11px;
  line-height: 18px;
  font-weight: 400;
  padding: 0px 0px 20px 0px;
  margin-top: -10px;
}
.pricing-4 .pricing-list {
  padding: 0px;
  margin-bottom: 20px;
}
.pricing-4 .pricing-list li {
  line-height: 40px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-weight: 500;
}
.pricing-4 .pricing-list li:last-child {
  border-bottom: none;
}
.pricing-single {
  max-width: 280px;
  margin: 0 auto;
}
.pricing-double-1 {
  width: 48%;
  float: left;
  margin-right: 4%;
}
.pricing-double-2 {
  width: 48%;
  float: left;
}
.pricing-list {
  list-style: none !important;
}
.pricing-list ul {
  list-style: none !important;
}
.pricing-list li {
  list-style: none !important;
}
.toast-tiny {
  min-width: 150px;
  width: fit-content;
  z-index: 90;
  position: fixed;
  left: 50%;
  line-height: 35px;
  padding: 0px 20px;
  color: #fff;
  transition: all 200ms ease;
  max-width: auto !important;
  font-size: 12px;
  border-radius: 60px;
  border: 0px !important;
}
.toast-bottom {
  bottom: 60px;
  bottom: calc(65px + (constant(safe-area-inset-bottom)) * 1.1) !important;
  bottom: calc(65px + (env(safe-area-inset-bottom)) * 1.1) !important;
  transform: translate(-50%, 60px);
}
.toast-bottom.toast-tiny.show {
  transform: translate(-50%, 0px);
}
.toast-top {
  top: 60px;
  top: calc(65px + (constant(safe-area-inset-bottom)) * 1.1) !important;
  top: calc(65px + (env(safe-area-inset-bottom)) * 1.1) !important;
  transform: translate(-50%, -60px);
}
.toast-top.toast-tiny.show {
  transform: translate(-50%, 0px);
}
.snackbar-toast {
  bottom: 70px !important;
  bottom: calc(70px + (constant(safe-area-inset-bottom)) * 1.1) !important;
  bottom: calc(70px + (env(safe-area-inset-bottom)) * 1.1) !important;
  position: fixed;
  left: 10px !important;
  right: 10px !important;
  padding: 0px 18px;
  line-height: 50px;
  border-radius: 8px;
  overflow: hidden;
  z-index: 95;
  transform: translateY(100px);
  transition: all 300ms ease;
}
.snackbar-toast.show {
  transform: translateY(0px);
}
.pagination {
  margin-bottom: 30px;
}
.pagination .page-link {
  padding: 10px 14px;
}
.pagination .page-item {
  margin-right: 5px;
  margin-left: 5px;
}
.page-item.active a {
  color: #fff !important;
}
.search-results {
  overflow: scroll;
}
.disabled-search-list {
  position: relative;
  max-height: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
  overflow: hidden;
}
.disabled-search {
  display: none;
}
.search-box {
  line-height: 50px;
  border: solid 1px rgba(0, 0, 0, 0.08);
}
.search-box input {
  outline: none !important;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 40px;
  line-height: 45px;
}
.search-box .fa-search {
  position: absolute;
  left: 0px;
  line-height: 52px;
  padding: 0px 15px 0px 30px;
}
.search-box a {
  position: absolute;
  right: 15px;
  font-size: 14px;
  line-height: 52px;
  width: 45px;
  text-align: center;
}
.search-box input.bg-white {
  color: #000 !important;
}
.search-color input {
  color: #fff;
}
.search-color input::placeholder {
  color: #fff;
}
.search-color i {
  color: #fff !important;
  z-index: 10;
}
.search-icon-list {
  padding-left: 10px;
  padding-top: 10px;
}
.search-icon-list a {
  color: #1f1f1f;
  line-height: 35px;
}
.search-icon-list a i {
  padding-right: 10px;
}
.search-page .search-result-list {
  position: relative;
  min-height: 80px;
  margin-bottom: 20px;
}
.search-page .search-results img {
  width: 80px;
  position: absolute;
  border-radius: 10px;
}
.search-page .search-results h1 {
  font-size: 15px;
  font-weight: 600;
  padding-left: 100px;
  margin-bottom: 0px;
}
.search-page .search-results p {
  font-size: 12px;
  line-height: 20px;
  padding-left: 100px;
  padding-right: 80px;
}
.search-page .search-results a {
  position: absolute;
  right: 0px;
  height: 30px;
  line-height: 31px;
  padding: 0px 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  border-radius: 10px;
}
.tab-content {
  display: none;
  overflow: hidden;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
}
.tab-controls a {
  border: solid 1px rgba(0, 0, 0, 0.07);
  border-right: none;
  text-align: center;
  float: left;
  font-size: 12px;
  margin-right: -1px;
  color: #1f1f1f;
  transition: all 250ms ease;
}
.tab-controls a:last-child {
  border: solid 1px rgba(0, 0, 0, 0.07);
  border-right: none !important;
}
.tabs-large a {
  height: 45px;
  line-height: 45px;
  font-size: 14px;
}
.tabs-medium a {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
}
.tabs-small a {
  height: 33px;
  line-height: 33px;
  font-size: 12px;
}
.tabs-rounded a:first-child {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.tabs-rounded a:last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.tabs-circled a:first-child {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}
.tabs-circled a:last-child {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
.working-hours {
  border-radius: 5px;
}
.working-hours p {
  padding-top: 34px;
  padding-bottom: 15px !important;
  line-height: 0px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -8px !important;
  width: 33.3%;
  float: left;
  margin-bottom: 10px;
  display: inline-block;
}
.working-hours p:nth-child(2) {
  text-align: center;
}
.working-hours p:last-child {
  text-align: right;
}
.working-hours::after {
  content: "";
  clear: both;
  display: table;
}
.max-iframe {
  max-height: 200px !important;
  height: 300px !important;
  padding-bottom: 0px !important;
  margin: 0 auto;
}
.responsive-iframe {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.responsive-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.responsive-iframe object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.responsive-iframe embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.footer {
  position: relative;
  padding: 0px 0px 10px 0px;
  margin-bottom: 0px !important;
  z-index: 7;
}
.footer strong {
  font-size: 10px;
  display: block;
  text-align: center;
  font-weight: 700;
  margin-top: 0px;
  text-transform: uppercase;
}
.footer strong i {
  padding: 0px 5px;
}
.footer .footer-text {
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  padding: 5px 10px 0px 10px;
}
.footer .footer-copyright {
  border-top: solid 1px rgba(0, 0, 0, 0.05);
}
.footer .footer-links {
  border-top: solid 1px rgba(0, 0, 0, 0.05);
  padding-top: 13px;
  display: block;
  text-align: center;
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.footer .footer-links a {
  padding: 40px 5px 1px 5px;
}
.footer .footer-title {
  color: #1f1f1f;
  width: 100%;
  font-size: 30px;
  text-align: center;
  font-weight: 800;
  font-family: "Poppins", sans-serif;
}
.footer .footer-logo {
  position: relative;
  width: 100%;
  left: auto;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0);
  position: relative;
  width: 100%;
  left: auto;
  margin: 0 auto;
}
.footer .footer-logo span {
  display: none !important;
}
.footer-title {
  text-align: center;
  display: block;
}
.footer-socials {
  max-width: 240px;
  margin: 0px auto 30px auto;
}
.footer-socials a {
  float: left;
  font-size: 14px;
  margin: 0px 4px;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08) !important;
}
.footer-socials a i {
  width: 38px;
  line-height: 38px;
  text-align: center;
  display: block;
  float: left;
}
.footer-socials:after {
  content: "";
  display: table;
  clear: both;
}
.footer-copyright {
  border-top: solid 1px rgba(255, 255, 255, 0.03);
  text-align: center;
  margin-bottom: -5px;
  padding-top: 15px;
  font-size: 11px;
  line-height: 24px;
}
.footer-logo span {
  display: none;
}
.calendar {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  max-width: 90%;
  margin: 0px auto 30px auto;
}
.cal-title {
  color: #fff;
  line-height: 60px;
  margin-bottom: 0px;
  padding: 0px 15px;
}
.cal-sub-title {
  color: #fff;
  line-height: 60px;
  margin-bottom: 0px;
  padding: 0px 15px;
}
.cal-title-right {
  float: right;
  margin-top: -60px;
  line-height: 62px;
  height: 50px;
  padding-right: 20px;
}
.cal-title-left {
  float: left;
  margin-top: -60px;
  line-height: 62px;
  height: 50px;
  padding-left: 20px;
}
.cal-days a {
  position: relative;
  width: 14.28%;
  float: left;
  text-align: center;
  height: 35px;
  line-height: 35px;
  position: relative;
  z-index: 3;
  font-weight: 800;
}
.cal-disabled {
  color: #ced4da !important;
}
.cal-selected span {
  z-index: 2;
  position: relative;
  color: #fff;
}
.cal-selected i {
  color: #000;
  z-index: 0;
  opacity: 1;
  font-size: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 6px 0px 0px -9px;
  text-align: center;
  line-height: 32px;
}
.cal-dates a {
  width: 14.2857142857%;
  float: left;
  text-align: center;
  line-height: 45px;
  color: #1f1f1f;
  font-weight: 500;
}
.theme-dark .cal-dates a {
  color: #fff !important;
}
.theme-dark .cal-disabled {
  opacity: 0.3;
}
.cal-dates-border a {
  overflow: hidden;
  border-right: solid 1px rgba(0, 0, 0, 0.05);
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.cal-schedule {
  min-height: 80px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.055);
}
.cal-schedule em {
  line-height: 20px;
  padding: 20px 0px 0px 20px;
  position: absolute;
  font-size: 11px;
  font-style: normal;
}
.cal-schedule strong {
  color: #000;
  display: block;
  padding: 17px 0px 0px 100px;
  font-size: 14px;
}
.cal-schedule span {
  display: block;
  font-size: 10px;
  color: #adb5bd;
  margin-top: 0px;
}
.cal-schedule span i {
  width: 50px;
  display: inline-block;
  text-align: center;
  padding: 0px 20px 0px 102px;
}
.theme-dark .cal-schedule strong {
  color: #fff !important;
}
.theme-dark .cal-schedule span {
  color: #fff !important;
  opacity: 0.5;
}
.cal-message {
  display: block;
}
.cal-message i {
  position: absolute;
  height: 40px;
  line-height: 43px;
  font-size: 27px;
  right: 20px;
}
.cal-message strong {
  display: block;
  line-height: 20px;
  font-weight: 400;
  font-size: 11px;
  padding-left: 20px;
}
.speech-left {
  max-width: 240px;
  float: right;
  border-bottom-right-radius: 0px !important;
  color: #fff;
}
.speech-right {
  max-width: 240px;
  float: left;
  border-bottom-left-radius: 0px !important;
}
.speach-image {
  padding: 0px !important;
}
.speach-image img {
  margin: 0px;
}
.speech-last {
  margin-bottom: 0px !important;
}
.speech-bubble {
  position: relative;
  border-radius: 17px;
  padding: 10px 15px;
  margin-bottom: 15px;
  line-height: 22px;
  font-size: 13px;
  background-color: #dee2e6;
  overflow: hidden;
}
.speech-bubble:after {
  content: "";
  display: block;
  clear: both;
}
.speech-read {
  font-size: 10px;
  font-style: normal;
  display: block;
  text-align: right;
  font-weight: 500;
  color: #adb5bd;
  margin-bottom: 80px;
}
.speach-input input {
  width: 100%;
  display: block;
  line-height: 45px;
  height: 45px;
  margin-top: 8px;
  border-radius: 45px;
}
.speach-icon a {
  padding-top: 0px !important;
  display: block;
  border-radius: 35px;
  width: 35px;
  height: 35px;
  line-height: 38px;
  margin-top: 3px;
  transform: translateY(10px);
}
.menu input[type="text"] {
  height: 35px;
  line-height: 35px;
}
.menu textarea {
  height: 85px !important;
  padding-top: 3px !important;
}
.menu .form-field {
  margin-bottom: 5px;
}
.menu .form-field label {
  font-size: 12px;
  margin-bottom: -10px;
}
.form-field span {
  position: absolute;
  right: 20px;
  font-size: 9px;
  opacity: 0.5;
}
.form-field input {
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 10px;
  margin: 0px 0px 15px 0px;
}
.form-field textarea {
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 10px;
  margin: 0px 0px 15px 0px;
}
.form-name input {
  line-height: 40px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 0px 10px 0px 10px;
}
.form-email input {
  line-height: 40px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 0px 10px 0px 10px;
}
.form-text textarea {
  height: 145px;
  line-height: 24px;
  width: 100%;
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 10px 10px 0px 10px;
  margin-bottom: 30px;
}
.contactSubmitButton {
  width: 100%;
  display: block;
  border-radius: 10px;
  color: #fff !important;
  padding: 12px 0px !important;
}
.fieldHasError {
  border: solid 1px #bf263c !important;
}
.map-full {
  position: relative;
  overflow: hidden;
}
.map-full .caption,
.map-full .card-overlay {
  transition: all 350ms ease;
}
.hide-map {
  position: absolute;
  width: 200px;
  left: 50%;
  bottom: 50px;
  margin-left: -100px;
  text-align: center;
  opacity: 0;
  z-index: 2;
}
.map-full .card {
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.map-full iframe {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
.activate-map {
  opacity: 1 !important;
  transition: all 250ms ease;
  transition: all 350ms ease;
}
.deactivate-map {
  opacity: 0 !important;
  pointer-events: none;
  transition: all 350ms ease;
}
.gallery-filter * {
  overflow: visible !important;
}
.gallery-filter-all {
  float: left !important;
  padding-left: 0px !important;
}
.gallery-filter-controls {
  padding: 0px;
  margin: 0px;
}
.gallery-filter-controls li {
  list-style: none;
  cursor: pointer;
  float: right;
  padding-left: 15px;
  font-weight: 500;
  color: #1f1f1f;
  font-size: 13px;
  transition: all 350ms ease;
}
.gallery-filter-controls li:first-child {
  padding-right: 30px;
}
.gallery-filter-controls {
  width: 100%;
  box-sizing: border-box;
  display: block;
}
.gallery-filter {
  transition: height 350ms ease;
  margin-left: -5px;
  margin-right: -5px;
}
.gallery-filter a {
  width: 33.3%;
  padding: 5px;
  display: block;
  transition: height 350ms ease;
}
.gallery-filter a img {
  display: block;
  width: 100%;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
}
.gallery-collection-round a img {
  border-radius: 10px;
}
.gallery-collection a {
  width: 48%;
  float: left;
  position: relative;
}
.gallery-collection a:nth-child(2n + 2) {
  margin-left: 4%;
  margin-bottom: 4%;
}
.gallery-collection a img {
  width: 50%;
  float: left;
  padding: 1px;
}
.gallery-collection i {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 50px;
  left: 50%;
  top: 50%;
  z-index: 10;
  margin-top: -25px;
  margin-left: -25px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 50px;
  font-size: 17px;
  color: #1f1f1f;
}
.gallery-view-controls a {
  float: left;
  width: 33.3%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: #1f1f1f;
}
.theme-dark .gallery-view-controls a {
  color: #fff;
}
.theme-dark .gallery-view-controls {
  border-bottom: solid 1px rgba(255, 255, 255, 0.07);
}
.gallery-view-controls {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}
.gallery-view-1 a {
  width: 30%;
  float: left;
}
.gallery-view-1 .caption {
  display: none;
}
.gallery-view-2 .caption {
  display: none;
}
.gallery-view-3 .caption {
  padding-top: 20px;
}
.gallery-view-1 a {
  margin-bottom: 5%;
}
.gallery-view-1 a:nth-child(3n-1) {
  margin: auto 5% auto 5%;
}
.gallery-view-2 a {
  width: 47.5%;
  margin-bottom: 5%;
  float: left;
}
.gallery-view-2 a:nth-child(2n-1) {
  margin-right: 5%;
}
.timeline-cover {
  height: 330px;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0px;
  background-position: center center;
  background-size: cover;
  margin-top: 50px;
}
.timeline-cover .overlay {
  opacity: 0.8;
}
.timeline-cover .content {
  z-index: 10;
}
.timeline-body {
  position: relative;
  margin-top: 200px;
  padding-top: 5px;
  z-index: 2;
  background-color: #f0f0f0;
  padding-bottom: 10px;
  box-shadow: none !important;
}
.timeline-header {
  height: 55px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
}
.timeline-header .back-button {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  color: #fff;
  z-index: 11;
}
.timeline-header .menu-icon {
  position: absolute;
  top: 2px;
  right: 0px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  color: #fff;
  z-index: 11;
}
.timeline-header .menu-icon em {
  background-color: #fff;
}
.timeline-logo {
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 10;
  width: 100%;
}
.timeline-deco {
  background-color: rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0px;
  z-index: 0;
  bottom: 0px;
  left: 43px;
  width: 1px;
}
.timeline-item-content,
.timeline-item-content-full {
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 0px 15px 30px 90px;
  padding: 20px 15px;
}
.timeline-item {
  position: relative;
  z-index: 2;
}
.timeline-item-content-full {
  padding: 0px;
}
.timeline-item-content-full h5 {
  padding: 5px 15px 15px 15px;
}
.timeline-item .timeline-icon {
  position: absolute;
  width: 45px;
  height: 45px;
  left: 21px;
  line-height: 45px;
  border-radius: 45px;
  top: 25px;
  font-size: 18px;
  text-align: center;
}
.timeline-cover-center .timeline-sub-heading,
.timeline-cover-center .timeline-heading {
  padding-left: 0px;
  text-align: center;
}
.timeline-cover-center .timeline-image {
  top: 50%;
  bottom: auto;
  margin-top: -150px;
  left: 50%;
  margin-left: -60px;
  background-size: 120px 120px;
  width: 120px;
  height: 120px;
  border-radius: 100px;
}
.timeline-body-center {
  padding-bottom: 40px;
}
.timeline-body-center .timeline-icon {
  width: 60px;
  height: 60px;
  left: 50%;
  line-height: 60px;
  margin-left: -31px;
  margin-top: -115px;
}
.timeline-body-center .timeline-deco {
  left: 50%;
  margin-left: -1px;
}
.timeline-body-center .timeline-item-content,
.timeline-body-center .timeline-item-content-full {
  margin: 120px 20px 20px 20px !important;
  padding: 20px;
}
.vcard-title {
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
}
.vcard-field {
  line-height: 50px;
  border-left: 0px !important;
  border-top: 0px !important;
  border-right: 0px !important;
  padding-top: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  position: relative;
}
.vcard-field strong {
  position: absolute;
  margin-top: -18px;
  color: #ababab;
  font-weight: 400;
  font-size: 10px;
}
.vcard-field i {
  position: absolute;
  top: 25px;
  width: 15px;
  text-align: center;
  right: 0px;
}
.vcard-field a {
  color: #000;
  font-weight: 400;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 10px;
  opacity: 0.7;
}
.bg-gradient {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0.1) 30%,
    rgba(0, 0, 0, 0.8) 70%,
    #000 100%
  );
}
.bg-gradient-fade {
  bottom: 0px !important;
}
.theme-light .bg-gradient-fade {
  background: linear-gradient(
    to bottom,
    rgba(240, 240, 240, 0) 0%,
    rgba(240, 240, 240, 0) 30%,
    rgba(240, 240, 240, 0.3) 40%,
    rgba(240, 240, 240, 0.5) 50%,
    #f0f0f0 80%,
    rgba(247, 247, 247, 0.95) 100%
  );
}
.theme-dark .bg-gradient-fade {
  background: linear-gradient(
    to bottom,
    rgba(27, 29, 33, 0) 0%,
    rgba(27, 29, 33, 0.1) 20%,
    rgba(27, 29, 33, 0.05) 40%,
    rgba(27, 29, 33, 0.6) 60%,
    rgba(27, 29, 33, 0.8) 80%,
    #1b1d21 100%
  );
}
.color-white {
  color: #fff !important;
}
.color-black {
  color: #000 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-black {
  background-color: #000 !important;
}
.theme-dark .bg-theme {
  background-color: #0f1117 !important;
}
.theme-light .bg-theme {
  background-color: #fff !important;
}
.theme-light .color-theme {
  color: #0f1117 !important;
}
.theme-dark .color-theme {
  color: #fff !important;
}
.theme-dark .show-on-theme-dark {
  display: block;
}
.theme-dark .show-on-theme-light {
  display: none;
}
.theme-light .show-on-theme-light {
  display: block;
}
.theme-light .show-on-theme-dark {
  display: none;
}
.theme-dark .dark-mode-tint {
  background-color: rgba(0, 0, 0, 0.5);
}
.theme-dark p {
  color: #fff;
}
.theme-dark h1,
.theme-dark h2,
.theme-dark h3,
.theme-dark h4,
.theme-dark h5,
.theme-dark h6,
.theme-dark #footer-bar a i,
.theme-dark #footer-bar a span .theme-dark p strong {
  color: #fff;
}
.theme-dark .accordion-btn,
.theme-dark .menu .nav-title,
.theme-dark .menu .nav a i,
.theme-dark .menu .nav a span,
.theme-dark .menu .nav .active-nav i,
.theme-dark .menu .nav .active-nav span,
.theme-dark .header .header-icon,
.theme-dark .header .header-title,
.theme-dark .search-header .fa-search,
.theme-dark .color-theme,
.theme-dark .countdown div,
.theme-dark .list-group i:last-child,
.theme-dark .search-icon-list a,
.theme-dark .fac label,
.theme-dark .pagination a,
.theme-dark .accordion-style-2 a,
.theme-dark .input-style-1 .input-icon,
.theme-dark .input-style-2 .input-icon,
.theme-dark .input-style-1 span,
.theme-dark .input-style-2 span,
.theme-dark .input-style-1 input,
.theme-dark .input-style-2 input,
.theme-dark input,
.theme-dark b,
.theme-dark .contact-form i,
.theme-dark .demo-icon-font article,
.theme-dark [data-tab],
.theem-dark #footer-bar a,
.theme-dark span:not(.badge),
.theme-dark .profile-2 .profile-stats a,
.theme-dark .vcard-field *,
.theme-dark .search-header input,
.theme-dark .search-header input::placeholder,
.theme-dark .footer .footer-title {
  color: #fff;
}
.theme-dark,
.theme-dark .page-bg,
.theme-dark body,
.theme-dark .loader-main,
.theme-dark .instant-article,
.theme-dark .divider-icon i,
.theme-dark .table tr.even td,
.theme-dark .timeline-body,
.theme-dark table,
.theme-dark td {
  background-color: #1b1d21 !important;
}
.theme-dark .ios-switch label::before,
.theme-dark .android-switch label::before {
  background-color: #1b1d21;
  border: solid 1px rgba(255, 255, 255, 0.05) !important;
}

.theme-dark .input-style-2 span {
  background-color: #000;
}
.theme-dark .card .input-style-2 span,
.theme-dark .menu .input-style-2 span,
.theme-dark .card.input-style span,
.theme-dark .bg-theme {
  background-color: #0f1117 !important;
}
.theme-dark .link-list-3 a,
.theme-dark .pricing-1,
.theme-dark .pricing-2,
.theme-dark .pricing-3,
.theme-dark .pricing-4,
.theme-dark .search-box,
.theme-dark .link-list-2 a,
.theme-dark .vcard-field,
.theme-dark table {
  border: solid 1px rgba(255, 255, 255, 0.05);
}
.theme-dark input::placeholder,
.theme-dark textarea::placeholder,
.theme-dark select::placeholder,
.theme-dark select,
.theme-dark .form-field input,
.theme-dark .form-field textarea {
  color: #fff !important;
  border-color: rgba(255, 255, 255, 0.05) !important;
}
.theme-dark .input-style-1 input,
.theme-dark .input-style-1 select,
.theme-dark .input-style-1 textarea,
.theme-dark input,
.theme-dark textarea,
.theme-dark select {
  border-color: rgba(255, 255, 255, 0.05);
}
.theme-dark .input-style-1 input:focus,
.theme-dark .input-style-1 select:focus,
.theme-dark .input-style-1 textarea:focus,
.theme-dark input:focus,
.theme-dark textarea:focus,
.theme-dark select:focus {
  border-color: #495057 !important;
}
.theme-dark .list-custom-large a,
.theme-dark .list-custom-small a {
  border-color: rgba(255, 255, 255, 0.05) !important;
}
.theme-dark .input-style-1 em,
.theme-dark .input-style-2 em {
  color: #898989;
}
.theme-dark .timeline-deco {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.theme-dark .footer-links,
.theme-dark #footer-bar,
.theme-dark .footer-copyright {
  border-top: solid 1px rgba(255, 255, 255, 0.05) !important;
}
.theme-dark .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
}
.theme-dark .border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.05) !important;
}
.theme-dark .border-left {
  border-left: 1px solid rgba(255, 255, 255, 0.05) !important;
}
.theme-dark .border-right {
  border-right: 1px solid rgba(255, 255, 255, 0.05) !important;
}
.theme-dark th,
.theme-dark .menu,
.theme-dark .header,
.theme-dark .polaroid-effect,
.theme-dark .timeline-item-content,
.theme-dark .tab-controls,
.theme-dark #footer-bar,
.theme-dark .menu .input-style span,
.theme-dark .timeline-item-content-full {
  background-color: #21252ae3;
  color: #fff;
}
.theme-dark .divider,
.theme-dark .menu .nav a:hover,
.theme-dark .menu .nav .active-subnav,
.theme-dark .menu .nav .divider {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-dark .menu-hider {
  background-color: rgba(0, 0, 0, 0.7);
}
.theme-dark .gallery-filter-controls li {
  color: #fff;
}
.theme-dark .gallery-view-controls a {
  color: #fff;
}
.theme-dark .gallery-view-controls {
  border-bottom: solid 1px rgba(255, 255, 255, 0.05);
}
.theme-dark .tab-controls a {
  border: solid 1px rgba(255, 255, 255, 0.05);
}
.theme-dark .form-control:focus,
.theme-dark .form-control {
  background-color: transparent !important;
}
.theme-dark .menu-header a,
.theme-dark .menu-items a {
  border-color: rgba(255, 255, 255, 0.04);
}

.demo-color {
  width: 100%;
  line-height: 45px;
  padding-left: 20px;
  text-transform: capitalize;
  border-bottom: solid 1px rgba(255, 255, 255, 0.05);
}
.demo-color span {
  font-size: 10px;
  position: absolute;
  right: 20px;
  line-height: 48px;
  color: rgba(255, 255, 255, 0.5);
}
.theme-change-transition {
  transition: all 150ms ease !important;
}
.highlight-changer a {
  width: 20%;
  float: left;
  text-align: center;
  line-height: 42px;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: -5px;
}
.highlight-changer a i {
  margin-top: 10px;
  display: block;
  font-size: 26px !important;
}
.highlight-changer a span {
  font-size: 11px;
  display: block;
  margin-top: -10px;
  margin-bottom: -5px;
}
.background-changer a {
  width: 20%;
  float: left;
  text-align: center;
  line-height: 42px;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: -5px;
}
.background-changer a i {
  margin-top: 10px;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  color: rgba(255, 255, 255, 0);
  margin: 10px auto 0px auto;
}
.background-changer .bg-theme {
  border: solid 1px rgba(0, 0, 0, 0.2);
}
.background-changer a span {
  font-size: 11px;
  display: block;
  margin-top: -10px;
  margin-bottom: -5px;
}
.lb-loader,
.lightbox {
  position: fixed;
  text-align: center;
  line-height: 0;
  left: 0;
}
.lb-dataContainer:after,
.lb-outerContainer:after {
  content: "";
  clear: both;
}
html.lb-disable-scrolling {
  overflow: hidden;
  position: fixed;
  height: 100vh;
  width: 100vw;
}
.lightboxOverlay {
  position: fixed;
  top: 0;
  bottom: 0 !important;
  left: 0;
  z-index: 9999;
  height: 100% !important;
  width: 100% !important;
  background-color: #000;
  filter: alpha(Opacity=80);
  opacity: 1;
  display: none;
  overflow: hidden;
}
.lightbox {
  right: 0;
  bottom: 0;
  top: 50% !important;
  transform: translateY(-50%);
  width: 100%;
  z-index: 10000;
  font-weight: 400;
}
.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
}
.lightbox a img {
  border: none;
}
.lb-outerContainer {
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
}
.lb-outerContainer:after {
  display: table;
}
.lb-loader {
  top: 50%;
  height: 25%;
  width: 100%;
}
.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
}
.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.lb-container > .nav {
  left: 0;
}
.lb-nav a {
  outline: 0;
  background-image: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==);
}
.lb-next,
.lb-prev {
  height: 100%;
  cursor: pointer;
  display: block;
}
.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}
.lb-nav a.lb-prev:hover {
  filter: alpha(Opacity=100);
  opacity: 1;
}
.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}
.lb-nav a.lb-next:hover {
  filter: alpha(Opacity=100);
  opacity: 1;
}
.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.lb-dataContainer:after {
  display: table;
}
.lb-data {
  padding: 0 4px;
  color: #ccc;
}
.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}
.lb-data .lb-caption a {
  color: #4ae;
}
.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999;
}
.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  text-align: right;
  outline: 0;
  filter: alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.lb-dataContainer,
.lb-details {
  width: 100% !important;
  position: fixed;
}
.lb-data .lb-close:hover {
  cursor: pointer;
  filter: alpha(Opacity=100);
  opacity: 1;
}
.lb-outerContainer {
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
}
.lb-dataContainer {
  bottom: -50%;
  left: 0;
  right: 0;
}
.lb-closeContainer {
  position: fixed;
  left: 50%;
  bottom: -50%;
  padding-bottom: 70px;
  transform: scale(0.8, 0.8);
  margin-left: -15px;
}
.lb-details {
  padding-top: 30px;
  top: -50%;
  left: 0;
  right: 0;
  text-align: center !important;
}
.lb-data .lb-caption {
  font-weight: 700;
  line-height: 1em;
  font-size: 16px;
  padding-bottom: 5px;
}
.task-slider {
  transform: translateX(-35px);
}
.task-slider .owl-stage-outer {
  overflow: visible !important;
}
.single-slider {
  z-index: 50 !important;
}
.single-slider img {
  width: 100% !important;
}
.under-slider-btn {
  z-index: 90;
  margin-top: -53px;
  margin-bottom: 0px;
}
.double-slider {
  padding-left: 15px !important;
  padding-right: 15px;
}
.double-slider .owl-stage-outer {
  overflow: visible !important;
}
.double-slider .under-slider-button {
  margin-bottom: -50px;
}
.cta-buttons {
  display: block;
  width: 280px;
  margin: 0px auto -1px auto;
  z-index: 10;
}
.cta-buttons a {
  display: inline-block;
}
.owl-has-controls .owl-nav {
  display: block !important;
}
.owl-has-controls .owl-prev {
  margin-left: 15px;
}
.owl-has-controls .owl-next {
  margin-right: 15px;
}
.slider-boxed {
  padding: 0px 15px;
}
.slider-boxed * {
  overflow: visible !important;
}
.slider-boxed .owl-nav {
  margin-top: -25px !important;
}
.slider-boxed .owl-dots {
  transform: translateX(0px) !important;
}
.carousel-boxed {
  padding: 0px 15px;
}
.card-slider * {
  overflow: visible !important;
}
.card-carousel * {
  overflow: visible !important;
}
.cover-slider .owl-dots {
  transform: translateY(-70px) !important;
}
.walkthrough-slider .owl-dots {
  transform: translateY(-22px) !important;
}
.cover-button-bottom {
  position: absolute;
  left: 50px;
  right: 50px;
  bottom: 70px;
  z-index: 99;
  font-size: 12px;
}
.cover-button-top {
  position: absolute;
  width: 170px;
  left: 50%;
  margin-left: -85px;
  top: 20px;
  top: calc(20px + (constant(safe-area-inset-top)) * 1.1) !important;
  top: calc(20px + (env(safe-area-inset-top)) * 1.1) !important;
  z-index: 99;
  font-size: 12px;
}
.cover-next {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: 0px;
}
.cover-prev {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  left: 0px;
}
.cover-slider {
  touch-action: pan-x;
}
.cover-slider .content {
  margin-bottom: 0px !important;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-carousel {
  -webkit-tap-highlight-color: transparent;
  position: relative;
  display: none;
  width: 100%;
  z-index: 1;
}
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  transform-style: preserve-3d;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav.disabled {
  display: none;
}
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav .owl-next {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.owl-carousel .card {
  overflow: hidden !important;
}
.owl-dots {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  text-align: center;
  bottom: 5px;
  width: 100%;
  backface-visibility: hidden;
}
.no-js .owl-carousel {
  display: block;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.owl-height {
  transition: all 250ms ease !important;
  min-height: 500px;
  height: auto !important;
  height: 500px;
}
.owl-auto-height .owl-height {
  transition: all 250ms ease-in-out !important;
}
.owl-dot {
  transition: all 250ms ease;
  border-radius: 50px;
  height: 8px;
  width: 8px;
  display: inline-block;
  background: rgba(139, 139, 139, 0.9);
  margin-left: 5px;
  margin-right: 5px;
}
.owl-dot.active {
  transition: all 250ms ease;
  background: #cacaca;
}
.owl-no-dots .owl-dots {
  display: none !important;
}
.owl-no-dots .cover-content-bottom {
  bottom: 0;
  padding-bottom: 0;
}
.content1-full-height .owl-dot {
  bottom: 20px !important;
}
.owl-nav {
  position: absolute;
  top: 50%;
  z-index: 10;
  left: 0;
  right: 0;
}
.owl-next {
  width: 40px;
  height: 50px;
  position: absolute;
  color: #fff;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  margin-top: -25px;
  right: 0;
}
.owl-prev {
  width: 40px;
  height: 50px;
  position: absolute;
  color: #fff;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  margin-top: -25px;
  left: 0;
}
.owl-has-dots .owl-dots {
  margin-top: 20px;
  margin-bottom: 0;
}
.owl-has-dots-over .owl-dots {
  margin-top: -40px;
  margin-bottom: 10px;
}
.cover-slider {
  touch-action: pan-x !important;
  margin-bottom: 0;
}
.cover-slider .owl-dots {
  z-index: 99;
  margin-top: -27px;
  transform: translateY(-20px) !important;
}
.cover-slider [data-height="cover"] {
  margin-bottom: 0 !important;
}
.next-slide {
  color: #fff;
  width: 35px;
  line-height: 35px;
  text-align: center;
  position: absolute;
  margin-top: -10px;
  top: 50%;
  transform: translateY(-70%);
  z-index: 99;
  right: 15px;
}
.prev-slide {
  color: #fff;
  width: 35px;
  line-height: 35px;
  text-align: center;
  position: absolute;
  margin-top: -10px;
  top: 50%;
  transform: translateY(-70%);
  z-index: 99;
  left: 15px;
}
.owl-dots-over .owl-dots {
  transform: translateY(-60px);
  margin-bottom: -10px;
}
.owl-dots-no-footer .owl-dots {
  transform: translateY(-18px) !important;
}
.double-slider {
  margin-left: -20px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.item {
  overflow: hidden;
}
